import React, { useState } from "react";
import Round from "./round";
import "./whakahuatanga.css";

const Whakahuatanga = () => {
  const [toggle, setToggle] = useState("vowels");
  return (
    <div className="whakahuatanga-wrapper">
      <div className="toggle">
        <div
          className={
            toggle === "vowels"
              ? "toggle-button-selected vowels"
              : "toggle-button vowels"
          }
          onClick={() => setToggle("vowels")}
        >
          <p className="text-maori">Oropuare</p>
          <p className="text-english">vowels</p>
        </div>
        <div
          className={
            toggle === "consonants"
              ? "toggle-button-selected consonants"
              : "toggle-button consonants"
          }
          onClick={() => setToggle("consonants")}
        >
          <p className="text-maori">Orotaki</p>
          <p className="text-english">consonants</p>
        </div>
      </div>
      <div
        className={toggle === "vowels" ? "whakahuatanga-item" : "display-none"}
      >
        <div className="card-body">
          <p>
            There are five vowel sounds in Māori. They can be pronounced ‘short’
            or ‘long’. The long vowel is marked with a macron, eg. <b>ā</b>.
          </p>
          <p>
            Where two different vowels appear together they retain their basic
            sound and run together.
          </p>

          <div className="vowel-container">
            <p className="vowel-guide">
              <div className="vowel-line">
                <Round letter="a" />{" "}
                <p>
                  as in c<b>u</b>p
                </p>
              </div>
              <div className="vowel-line">
                <Round letter="e" />{" "}
                <p>
                  as in <b>e</b>gg
                </p>
              </div>
              <div className="vowel-line">
                <Round letter="i" />{" "}
                <p>
                  <b>i</b> as in <b>e</b>at
                </p>
              </div>
              <div className="vowel-line">
                <Round letter="o" />{" "}
                <p>
                  as in f<b>o</b>r
                </p>
              </div>
              <div className="vowel-line">
                <Round letter="u" />{" "}
                <p>
                  as in f<b>o</b>r
                </p>
              </div>
              <div className="vowel-line">
                <Round letter="u" />{" "}
                <p>
                  as in t<b>o</b>
                </p>
              </div>
            </p>
            <p className="vowel-guide">
              <div className="vowel-line">
                <Round letter="a" />{" "}
                <p>
                  as in c<b>u</b>p
                </p>
              </div>
              <div className="vowel-line">
                <Round letter="ā" />{" "}
                <p>
                  as in c<b>a</b>r
                </p>
              </div>
              <div className="vowel-line">
                <Round letter="ē" />{" "}
                <p>
                  as in m<b>ea</b>sure
                </p>
              </div>
              <div className="vowel-line">
                <Round letter="ī" />{" "}
                <p>
                  as in h<b>ee</b>l
                </p>
              </div>
              <div className="vowel-line">
                <Round letter="ō" />{" "}
                <p>
                  as in y<b>ou</b>r
                </p>
              </div>
              <div className="vowel-line">
                <Round letter="ū" />{" "}
                <p>
                  as in r<b>oo</b>f
                </p>
              </div>
            </p>
          </div>
        </div>
      </div>

      <div
        className={
          toggle === "consonants" ? "whakahuatanga-item" : "display-none"
        }
      >
        <div className="card-body">
          <p>
            There are 10 consonants they are: <br />
            <div className="row">
              <Round letter="h" />
              <Round letter="k" />
              <Round letter="m" />
              <Round letter="n" />
              <div className="round-two">ng</div>
              <Round letter="p" />
              <Round letter="r" />
              <Round letter="t" />
              <Round letter="w" />
              <div className="round-two">wh</div>
            </div>
          </p>
          <p className="vowel-guide">
            <p>
              <b>ng</b> as in wi<b>ng</b>er
            </p>
            <p>
              <b>wh</b> as in <b>f</b>ilm
            </p>
            <p>
              <b>r</b> is rolled as in u<b>dd</b>er or pu<b>dd</b>le
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Whakahuatanga;
