import "./NavItem.css";
import React, { useContext } from "react";
import {
  GlobalDispatchContext,
  GlobalStateContext
} from "../context/GlobalContextProvider";

export default function NavItem(props) {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  console.log(dispatch);
  console.log(state);
  return (
    <div className="nav-item">
      <div
        className="nav-item-title"
        onClick={() => {
          dispatch({
            type: "CHANGE_NAV",
            nav: state.nav !== props.TitleEnglish ? props.TitleEnglish : ""
          });
        }}
      >
        <p className="bold">
          {props.TitleMaori}
          &nbsp; |
        </p>
        <p className="light">&nbsp; {props.TitleEnglish}</p>
        <p className="expand-close">
          {state.nav === props.TitleEnglish ? "-" : "+"}
        </p>
      </div>
      <div
        className="nav-item-content"
        style={state.nav === props.TitleEnglish ? { maxHeight: "700px" } : {}}
      >
        {props.children}
      </div>
    </div>
  );
}
