import React, { useState } from "react";
import "./mihi.css";

const Mihi = () => {
  const [toggle, setToggle] = useState("takenga");
  return (
    <div className="mihi">
      <div className="mihi-container">
        <div className="toggle">
          <div
            className={
              toggle === "takenga"
                ? "toggle-button-selected vowels"
                : "toggle-button vowels"
            }
            onClick={() => setToggle("takenga")}
          >
            <p className="text-maori">Takenga</p>
            <p className="text-english">reference</p>
          </div>
          <div
            className={
              toggle === "ropu"
                ? "toggle-button-selected consonants"
                : "toggle-button consonants"
            }
            onClick={() => setToggle("ropu")}
          >
            <p className="text-maori">Rōpu</p>
            <p className="text-english">team</p>
          </div>
        </div>
        <div className={toggle === "takenga" ? "mihi-item" : "display-none"}>
          <div className="mihi-body">
            <p>
              The individual word translations on Pūkare have been sourced from{" "}
              <a
                href="https://maoridictionary.co.nz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Māori Dictionary
              </a>
              , and{" "}
              <a href="https://natlib.govt.nz/records/21106930">
                The Reed Dictionary of Modern Māori.
              </a>
            </p>
            <p>
              For more te reo Māori information and resources, visit{" "}
              <a
                href="https://www.tetaurawhiri.govt.nz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Te Taura Whiri i te Reo Māori
              </a>
              .
            </p>
            <p>
              Pūkare was created in support of{" "}
              <a
                href="https://www.tewikiotereomaori.co.nz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Te Wiki o te Reo Māori 2019
              </a>
              .
            </p>
          </div>
        </div>

        <div className={toggle === "ropu" ? "mihi-item" : "display-none"}>
          <div className="mihi-body">
            <p>
              <a
                href="https://www.apopo.nz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ĀPŌPŌ
              </a>{" "}
              - a programme growing and supporting indigenous futures in
              creativetech, design and innovation. Pūkare was created by the
              <b>ĀPŌPŌ</b> team:{" "}
              <b>
                Victoria Kaihe, Hemi Takimoana, Brendon Drury, Blaine Western,
                Luke Enoka.
              </b>
            </p>
            <p>
              <a
                href="https://www.idia.nz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                IDIA
              </a>{" "}
              - a cultural creative agency designing futures through an
              indigenous lens, and building the capability and capacity of
              commercial indigenous designers. Supporting the <b>ĀPŌPŌ</b> team
              to create <b>Pūkare</b> were{" "}
              <b>Miriame Barbarich, John Moore and Johnson Witehira.</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mihi;
