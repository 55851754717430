import React, { useContext, useState } from "react";
import Carousel from "nuka-carousel";
import Fullscreen from "react-full-screen";

import { GlobalStateContext } from "../context/GlobalContextProvider";

import Layout from "../components/layout";
import NavBar from "../components/navBar";

import expand from "../images/expand.svg";
import contract from "../images/contract.svg";
import pause from "../images/pause.svg";
import play from "../images/play.svg";
import next from "../images/next.svg";
import previous from "../images/previous.svg";
import idia from "../images/idia-logo.svg";

import "./whakamatakitaki.css";

export default function whakamatakitaki(props) {
  const state = useContext(GlobalStateContext);
  const [playPause, setPlayPause] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);

  return (
    <Fullscreen enabled={fullscreen} onChange={isFull => setFullscreen(isFull)}>
      <Layout>
        {!fullscreen && <NavBar />}
        <div className="whakamatakitaki-container">
          <div className="slide-wrapper">
            <Carousel
              withoutControls={!!fullscreen}
              swiping={true}
              pauseOnHover={false}
              autoplayInterval={state.speed}
              transitionMode={state.transition}
              autoplay={playPause}
              wrapAround={true}
              renderBottomCenterControls={null}
              renderCenterLeftControls={({ previousSlide }) => (
                <div className="previous-button" onClick={previousSlide}>
                  <img src={previous} alt="previous" />
                </div>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <div className="next-button" onClick={nextSlide}>
                  <img src={next} alt="next" />
                </div>
              )}
            >
              {props.children}
            </Carousel>
          </div>
          <div className="collection-name">
            <b>{props.collectionName}</b>
            <p>{props.subtitle}</p>
          </div>
          <div className="controls-wrapper">
            <div className="controls-visible">
              <div
                className="display-button play-pause"
                onClick={() => setPlayPause(!playPause)}
              >
                {!fullscreen && (
                  <img src={playPause ? pause : play} alt="play" />
                )}
              </div>
              <div
                className="display-button expand"
                onClick={() => setFullscreen(!fullscreen)}
              >
                <img src={fullscreen ? contract : expand} alt="contract" />
              </div>
            </div>
          </div>
          <div className="IDIA-logo-display">
            <a href="https://www.idia.nz/">
              <img src={idia} alt="IDIA" />
            </a>
          </div>
        </div>
      </Layout>
    </Fullscreen>
  );
}
