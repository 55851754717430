import React, { Component } from "react";
import { Link } from "gatsby";

import NavItem from "./NavItem";
import KohingaButton from "./KohingaButton";
import Settings from "./Settings";
import Mihi from "./mihi";
import Whakahuatanga from "./whakahuatanga";

import "./navBar.css";

class MainNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      expanded: "settings"
    };
    this.checkBoxInput = React.createRef();
    this.checkHandler = this.checkHandler.bind(this);
  }

  checkHandler() {
    this.setState({ checked: !this.state.checked });
    console.log(this.state.checked);
  }

  render() {
    return (
      <div className="nav-container">
        <div className="nav-items">
          <div className="nav-links" id="menuToggle">
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                checked={this.state.checked}
                onChange={() => this.checkHandler()}
              />
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul id={this.state.checked ? "menu-checked" : "menu-unchecked"}>
              <div className="nav-tems-wrapper">
                <div className="home-link" onClick={() => this.checkHandler()}>
                  <Link className="nav-link" to="/">
                    <p className="bold">Kāinga |</p>
                    <p className="light">&nbsp; Home</p>
                  </Link>
                </div>
                <NavItem TitleMaori="Kohinga" TitleEnglish="Collections">
                  <div className="collections">
                    <KohingaButton
                      maori="Kei Te Tari"
                      english="At The Office"
                      link="kei-te-tari"
                    />
                    <KohingaButton
                      maori="Kei Te Kāinga"
                      english="At Home"
                      link="kei-te-kainga"
                    />
                    <KohingaButton
                      maori="Kīwaha"
                      english="Idioms"
                      link="kiwaha"
                    />
                  </div>
                </NavItem>
                <NavItem
                  TitleMaori="Whakahuatanga"
                  TitleEnglish="Pronunciation"
                >
                  <Whakahuatanga />
                </NavItem>
                <NavItem TitleMaori="Tautuhinga" TitleEnglish="Settings">
                  <Settings />
                </NavItem>
                <NavItem TitleMaori="Mihi" TitleEnglish="Acknowledgement">
                  <Mihi />
                </NavItem>
              </div>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default MainNav;
