import React, { useContext } from "react";
import {
  GlobalDispatchContext,
  GlobalStateContext
} from "../context/GlobalContextProvider";

import "./Settings.css";

const Settings = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  console.log(dispatch);
  console.log(state);
  return (
    <div className="settings-wrapper">
      <div className="settings-speed">
        <p className="bold-small">Seconds between slides</p>
        <div className="button-wrapper">
          <div
            className={
              state.speed === 5000
                ? "settings-button-selected"
                : "settings-button"
            }
            onClick={() => {
              dispatch({ type: "CHANGE_SPEED", newSpeed: 5000 });
            }}
          >
            5
          </div>
          <div
            className={
              state.speed === 10000
                ? "settings-button-selected"
                : "settings-button"
            }
            onClick={() => {
              dispatch({ type: "CHANGE_SPEED", newSpeed: 10000 });
            }}
          >
            10
          </div>
          <div
            className={
              state.speed === 17000
                ? "settings-button-selected"
                : "settings-button"
            }
            onClick={() => {
              dispatch({ type: "CHANGE_SPEED", newSpeed: 17000 });
            }}
          >
            17
          </div>
          <div
            className={
              state.speed === 25000
                ? "settings-button-selected"
                : "settings-button"
            }
            onClick={() => {
              dispatch({ type: "CHANGE_SPEED", newSpeed: 25000 });
            }}
          >
            25
          </div>
          <div
            className={
              state.speed === 35000
                ? "settings-button-selected"
                : "settings-button"
            }
            onClick={() => {
              dispatch({ type: "CHANGE_SPEED", newSpeed: 35000 });
            }}
          >
            35
          </div>
        </div>
      </div>
      <div className="settings-transition">
        <p className="bold-small">Seconds between slides</p>
        <div className="button-wrapper">
          <div
            className={
              state.transition === "scroll"
                ? "settings-button-selected"
                : "settings-button"
            }
            onClick={() => {
              dispatch({ type: "CHANGE_TRANSITION", newTransition: "scroll" });
            }}
          >
            scroll
          </div>
          <div
            className={
              state.transition === "fade"
                ? "settings-button-selected"
                : "settings-button"
            }
            onClick={() => {
              dispatch({ type: "CHANGE_TRANSITION", newTransition: "fade" });
            }}
          >
            fade
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
