import React from "react";
import { Link } from "gatsby";

import "./KohingaButton.css";

export default function KohingaButton({ maori, english, link }) {
  return (
    <Link to={link}>
      <div className="link-button">
        <p className="text-maori">{maori}</p>
        <p className="text-english">{english}</p>
      </div>
    </Link>
  );
}
