import React from "react";
import PropTypes from "prop-types";

import GlobalContextProvider from "../context/GlobalContextProvider";

import "./layout.css";
import poutama from "../images/poutama.svg";

const Layout = ({ children }) => {
  return (
    <>
      <GlobalContextProvider>
        <main
          style={{
            margin: `0`,
            width: `100%`,
            height: `100%`,
            paddingTop: 0,
            backgroundImage: `url(${poutama})`,
            backgroundRepeat: `repeat-y`,
            backgroundColor: `#1A1C37`,
            backgroundSize: `100vw`
          }}
        >
          {children}
        </main>
      </GlobalContextProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
